.Head__Wrapper {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "MBold";
  margin: 0 auto;
  font-size: 18px;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
}

.Header__Logotype {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: default;
  padding-right: 2rem;
}

.Header__Logotype__Name {
  position: relative;
  letter-spacing: 0.05rem;
  color: #4b4b4b;
  cursor: pointer;
}

.Nav__Item__Btn {
  color: #1e9eab;
}

.Header__Logotype__Image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 70px;
  padding-bottom: .75rem;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header__Logotype__Name {
  text-transform: uppercase;
}

.Nav__Item a,
.Nav__Item p,
.Nav__Item li {
  cursor: pointer;
  letter-spacing: 0.025rem;
}

.Nav__Item {
  padding-left: 1.5rem;
}

.Nav__Item ul {
  list-style-type: none;
}

.Nav__List {
  display: flex;
  flex-direction: row;
}

.Nav__Item__List {
  position: absolute;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s ease;
  box-shadow: rgba(99, 99, 99, 0.432) 0px 2px 8px 0px;
  border-radius: 20px;
  margin-left: -24px;
}

.Nav__Item__List li {
  margin: 1.5em;
  font-size: 16px;
}

.Nav__Item__Btn > p,
.Nav__Item > a,
.Nav__Item__List li > a {
  transition: 0.3s ease;
}

.Nav__Item__Btn > p:hover,
.Nav__Item > a:hover,
.Nav__Item__List li > a:hover {
  color: #f7bb73;
}

.Nav__Item:hover .Nav__Item__List {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s ease;
}

.Nav__Burger__Btn {
  display: none;
  opacity: 0;
  visibility: hidden;
}

p.disabled {
  cursor: not-allowed;
  user-select: none;
  color: rgba(75, 75, 75, 0.404);
}

p.disabled::after {
  content: "Soon";
  color: white;
  background-color: #48b877;
  border-radius: 5px;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
}

/* Mobile Devices */

@media (max-width: 480px) {
  nav {
    width: 40px;
  }

  .fixed {
    .Header__Logotype {
      position: fixed;
    }

    .Nav__Burger__Btn {
      top: calc(4.75rem + 13px);
      position: fixed;
    }

    padding-bottom: 4.35rem;
  }

  .Header__Logotype {
    position: relative;
    z-index: 1;
  }

  .Nav__Wrapper {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  .Nav__Burger__Btn {
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
    display: block;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }

  .Nav__Burger__Btn span {
    position: absolute;
    top: 9px;
    width: 30px;
    height: 2px;
    background-color: #489eb8;
  }

  .Nav__Burger__Btn span:before {
    left: 0;
    content: "";
    position: absolute;
    top: -9px;
    width: 30px;
    height: 2px;
    background-color: #489eb8;

    border-radius: 20px;
    transition-duration: 0.25s;
    transition: transform 0.25s, top 0.25s 0.25s;
  }

  .Nav__Burger__Btn span:after {
    left: 0;
    content: "";
    position: absolute;
    top: 9px;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #489eb8;

    border-radius: 20px;
    transition-duration: 0.25s;
    transition: transform 0.25s, top 0.25s 0.25s;
  }

  .Nav__Burger__Btn.open span {
    transition-duration: 0.1s;
    transition-delay: 0.25s;
    background: transparent;
  }
  .Nav__Burger__Btn.open span:before {
    transition: top 0.25s, transform 0.25s 0.25s;
    top: 0px;
    transform: rotateZ(-45deg);
  }
  .Nav__Burger__Btn.open span:after {
    transition: top 0.4s, transform 0.25s 0.25s;
    top: 0px;
    transform: rotateZ(45deg);
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  nav {
    width: 40px;
  }

  .fixed {
    .Header__Logotype {
      position: fixed;
    }

    .Nav__Burger__Btn {
      top: calc(4.75rem + 13px);
      position: fixed;
    }

    padding-bottom: 4.35rem;
  }

  .Header__Logotype {
    position: relative;
    z-index: 1;
  }

  .Nav__Wrapper {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  .Nav__Burger__Btn {
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
    display: block;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }

  .Nav__Burger__Btn span {
    position: absolute;
    top: 9px;
    width: 30px;
    height: 2px;
    background-color: #489eb8;
  }

  .Nav__Burger__Btn span:before {
    left: 0;
    content: "";
    position: absolute;
    top: -9px;
    width: 30px;
    height: 2px;
    background-color: #489eb8;

    border-radius: 20px;
    transition-duration: 0.25s;
    transition: transform 0.25s, top 0.25s 0.25s;
  }

  .Nav__Burger__Btn span:after {
    left: 0;
    content: "";
    position: absolute;
    top: 9px;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #489eb8;

    border-radius: 20px;
    transition-duration: 0.25s;
    transition: transform 0.25s, top 0.25s 0.25s;
  }

  .Nav__Burger__Btn.open span {
    transition-duration: 0.1s;
    transition-delay: 0.25s;
    background: transparent;
  }
  .Nav__Burger__Btn.open span:before {
    transition: top 0.25s, transform 0.25s 0.25s;
    top: 0px;
    transform: rotateZ(-45deg);
  }
  .Nav__Burger__Btn.open span:after {
    transition: top 0.4s, transform 0.25s 0.25s;
    top: 0px;
    transform: rotateZ(45deg);
  }
}