$atlantida: #f7bb73;
$alternate: #a1e9f3;

.button__main > span > p,
.button__marquee__inner > span > p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button__main {
  padding: 0;
  width: 150px;
  height: 50px;
  margin-top: 1rem;
  margin-right: 1rem;
  outline: none;
  color: black;
  cursor: pointer;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: $atlantida;
  border: none;
  user-select: none;
  display: inline-block;
  border-radius: 30px;
  font-family: "MBold";
}

.button__marquee {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}

.button__marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  --offset: 3rem;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 1s linear infinite;
  animation-play-state: running;
  opacity: 0;
}

.button__marquee span {
  text-align: center;
  white-space: nowrap;
  font-style: italic;
  padding: 1rem 0.5rem;
}

.button__main:hover > span > p {
  opacity: 0;
}

.button__main:hover {
  .button__marquee__inner {
    animation-play-state: running;
    opacity: 1;
    transition-duration: 0.3s;
  }
}

.button__main:hover {
  background-color: $atlantida;
}

.button__main:active {
  background-color: $alternate;
}

@keyframes marquee {
  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@media (max-width: 480px) {
  .button__main {
    width: 150px;
    height: 50px;
    z-index: -10;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .altButton {
    width: 47%;
  }

  .button__main {
    width: 100%;
    font-size: 1rem;
    z-index: -10;
  }
}