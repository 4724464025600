.NotFoundPage__Wrapper {
    height: 100vh;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    footer {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
