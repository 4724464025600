.AtlantidaCoin {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 4rem;
  color: #4b4b4b;
}

h2 span {
  display: inline-block;
  color: #1e9eab;
}

.AtlantidaCoin__Information::selection {
  color: #489eb8;
}

.AtlantidaCoin__Information {
  width: 100%;
  padding-bottom: 1.6rem;
  font-family: "MLight";
}

.AtlantidaCoin__Wrapper {
  width: 500px;
  padding-top: 4rem;
  padding-bottom: 0;
  margin-left: 2rem;
}

.AtlantidaCoin__Wrapper__Cryptocurrency {
  display: flex;
  flex-direction: column;
}

.tip {
  display: flex;
  align-items: center;
}

.tip__span {
  opacity: 0;
  user-select: none;
  margin-bottom: 1.75rem;

  background-image: linear-gradient(#1e9eab, #1e9eab);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 3px;
  transition: background-size 0.5s, opacity 0.3s ease-in;
}

.tip > a {
  display: grid;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 12px;
  font-family: "MRegular";
  transition: 0.3s ease;
  margin: 0.5rem;
  margin-left: 0.9rem;
  margin-right: 1.25rem;
  color: #4b4b4b;
}

.tip > a:hover {
  opacity: 1;
}

.tip:hover {
  .tip__span {
    opacity: 1;
    background-size: 100% 3px;
  }
}

.AtlantidaCoin__Wrapper__Cryptocurrency > div:last-child > span {
  color: rgba(75, 75, 75, 0.404);
  background-image: linear-gradient(
    rgba(75, 75, 75, 0.404),
    rgba(75, 75, 75, 0.404)
  );
}

.AtlantidaCoin__Image {
  padding-top: 4rem;
  max-width: 548px;
  pointer-events: none;
}

.Cryptocurrency__Icon {
  width: 44px;
  height: 44px;
  margin: 0.5rem 0;
  transition: 0.3s ease;
  fill: #1e9eab;
  pointer-events: none;
}

.tip > a > p {
  font-family: "MBold";
  font-size: 16px;
  color: #1e9eab;
  transition: 0.3s ease;
}

.tip > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  width: 83px;
  height: 80px;
  margin-bottom: 1rem;
}

.Cryptocurrency__Disabled__Name {
  position: relative;
}

a.disabled {
  cursor: not-allowed;

  svg {
    fill: rgba(75, 75, 75, 0.404);
  }

  p {
    user-select: none;
    color: rgba(75, 75, 75, 0.404);
  }

  p::after {
    position: absolute;
    top: 22px;
    left: 17px;
    content: "Soon";
    color: white;
    background-color: #48b877;
    border-radius: 5px;
    padding: 0 0.25rem;
    margin-left: 0.25rem;
  }
}

/* Mobile Devices */

@media (max-width: 1000px) {
  .AtlantidaCoin__Image,
  .tip__span
  {
    display: none;
  }

  .AtlantidaCoin__Wrapper {
    margin: 0;
    margin-top: -1.75rem;
    padding: 0;
  }

  .tip > a {
    margin: 0;
    opacity: 1;
  }

  .AtlantidaCoin__Wrapper__Cryptocurrency {
    flex-direction: row;
    justify-content: space-between;
  }

  .Cryptocurrency__Icon {
    margin-right: 0;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .AtlantidaCoin__Information {
    font-size: 1.5rem;
  }

  .tip > a {
    min-height: 100px;
  }

  .tip > a > svg {
    width: 64px;
    height: 64px;
  }

  .tip > a > p {
    font-size: 1rem;
  }
}