.WhitePaper {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  color: #4b4b4b;
}

h2 {
  font-family: "MBlack";
  margin: 1.75rem 0;
}

.WhitePaper__Information::selection {
  color: #489eb8;
}

.WhitePaper__Information {
  width: 85%;
  padding-bottom: 1.6rem;
  font-family: "MLight";
}

.WhitePaper__Wrapper {
  width: 500px;
  padding-top: 4rem;
  padding-right: 2rem;
  padding-bottom: 0;
}

.WhitePaper__Wrapper__Languages {
  display: flex;
  flex-direction: row;
}

.WhitePaper__Wrapper__Languages > a {
  display: grid;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 12px;
  font-family: "MRegular";
  transition: 0.3s ease;
  margin-right: 1.75rem;
  color: #4b4b4b;
}

.WhitePaper__Wrapper__Languages > a:not(:first-child) {
  width: 91px;
}

a.active:hover {
  color: #489eb8;
  .Languages__Flag {
    box-shadow: rgba(99, 99, 99, 0.39) 0px 2px 8px 3px;
    background-color: rgba(99, 99, 99, 0.096);
    border-radius: 50%;
    opacity: 1;
  }
}

.Languages__Flag {
  opacity: 0.8;
  padding: 0.25rem;
  margin-bottom: 0.75rem;
  transition: 0.3s ease;
  pointer-events: none;
}

.disabled {
  filter: grayscale(70%);
  cursor: not-allowed;
}

.ua {
  filter: grayscale(25%);
  cursor: not-allowed;
}

.WhitePaper__Wrapper__Languages [data-tooltip]::after{
  display: flex;
  font-family: "MBold";
  justify-content: center;
  align-items: center;
  text-align: center;
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;
  color: #4b4b4b;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.226) 0px 2px 8px 3px;
  border-radius: 20px;
  font-size: 10px;
  width: 90px;
  height: 70px;
  padding: 0.15rem;

  transition: 0.3s ease;
}

.WhitePaper__Wrapper__Languages [data-tooltip]:hover::after{
  opacity: 1;
}

/* Mobile Devices */

@media (max-width: 480px) {
  .WhitePaper__Wrapper__Languages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .WhitePaper__Wrapper__Languages > a {
    margin-bottom: 1.75rem;
  }

  .Languages__Flag {
    z-index: 0;
  }

  .WhitePaper__Wrapper__Languages > a:not(:first-child) {
    width: auto;
  }

  h2 {
    font-size: 1.4rem;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .WhitePaper__Wrapper__Languages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .WhitePaper__Wrapper__Languages > a {
    margin-bottom: 1.75rem;
    margin-right: 2rem;
  }

  .Languages__Flag {
    width: 64px;
    height: 64px;
    z-index: 0;
  }

  .WhitePaper__Wrapper__Languages > a:not(:first-child) {
    width: auto;
  }

  .WhitePaper__Wrapper__Languages > a > p {
    font-size: 1rem;
  }

  .WhitePaper__Wrapper__Languages [data-tooltip]::after{
    width: 7rem;
    height: 7rem;
    font-size: 1rem;
  }

  .WhitePaper__Wrapper {
    width: 100%;
  }

  .WhitePaper__Information {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 2rem;
  }
}