.CoinChannels__Wrapper {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: #4b4b4b;
  padding-top: 4rem;
}

h2 {
  font-family: "MBlack";
  margin: 1.75rem 0;
}

h2 span {
  color: #1e9eab;
}

.CoinChannels__Channels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 2.5rem;
}

.Channel {
  display: flex;
  flex-direction: row;
}

.Channel__Image{
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}

.Channel__Background__Image {
  position: absolute;
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
}

.Channel__Text__Wrapper {
  margin: 0 1rem;
}

.Channel__Name {
  font-family: "MBold";
  margin-bottom: 0.5rem;
  transition: 0.3s ease;
}

.Channel__Name:hover {
  color: #4b4b4b;
}

.Channel__Description {
  font-family: "MLight";
  color: rgba(75, 75, 75, 0.705);
  font-size: 14px;
}

@media (max-width: 1000px) {
  .CoinChannels__Channels {
    grid-template-columns: 1fr;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .Channel__Name {
    font-size: 1.5rem;
  }

  .Channel__Description {
    font-size: 1.25rem;
  }
  
  .CoinChannels__Channels {
    grid-row-gap: 3rem;
  }
}
