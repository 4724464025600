.Banner {
  width: 100%;
  height: 300px;
  margin-top: 4rem;
  border-radius: 20px;
  object-fit: cover;
  animation: slide 1.5s ease-in;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes slide {
  0% {
    object-position: 50% 0%;
  }
  70% {
    object-position: 50% 85%;
  }
  100% {
    object-position: 50% 80%;
  }
}

@media (max-width: 1000px) {
  .Banner {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
