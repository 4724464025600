.Menu__Item a,
.Menu__Item p,
.Menu__Item li {
  cursor: pointer;
  letter-spacing: 0.025rem;
}

.Menu__Wrapper {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  animation: burger_open 0.5s ease;
}

.close {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  animation: burger_close 0.5s ease;
}

.Menu__List {
  position: absolute;
  font-size: 18px;
  font-family: "MBold";
  top: calc(100vh / 2 - 120px / 2);
  left: calc(100vw / 2 - 150px / 2);
}

.Menu__Item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.Menu__Item__Btn > p,
.Menu__Item > a {
  transition: 0.3s ease;
}

.Menu__Item__Btn > p:hover,
.Menu__Item > a:hover {
  color: #f7bb73;
}

.Menu__Links__Wrapper {
  position: absolute;
  bottom: 4rem;
  left: calc(100vw / 2 - 150px / 2);
}

.Menu__Link:nth-of-type(even) {
  margin: 0 1rem;
}

.Menu__Link:hover > svg {
  fill: #489eb8;
}

.Menu__Link__SVG {
  width: 30px;
  height: 30px;
  margin: 0;
  transition: 0.3s ease;
  fill: #4b4b4b;
}

p.disabled {
  cursor: not-allowed;
  user-select: none;
  color: rgba(75, 75, 75, 0.404);
}

p.disabled::after {
  content: "Soon";
  color: white;
  background-color: #48b877;
  border-radius: 5px;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
}

@keyframes burger_open {
  0% {
    transform: translateX(+100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes burger_close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(+100%);
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .Menu__List {
    font-size: 1.75rem;
    top: calc(100vh / 2 - 210px / 2);
    left: calc(100vw / 2 - 260px / 2);
  }

  .Menu__Link__SVG {
    width: 48px;
    height: 48px;
  }

  .Menu__Links__Wrapper {
    position: absolute;
    bottom: 4rem;
    left: calc(100vw / 2 - 116px);
  }
}