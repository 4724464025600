.Footer__Wrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  margin-top: 8rem;
  margin-bottom: 2rem;
}

.Footer__Navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(75, 75, 75, 0.5);
  border-bottom: 1px solid rgba(75, 75, 75, 0.5);
  padding: 2rem 0;
}

.Footer__Logotype {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: default;
}

.Footer__Logotype__Name {
  position: relative;
  letter-spacing: 0.05rem;
  color: #4b4b4b;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "MBold";
}

.Footer__Logotype__Image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 50px;
  padding-bottom: .5rem;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.Nav__Wrapper {
  padding-right: 2rem;
  padding-bottom: 0;
  font-family: "MBold";
  font-size: 14px;
}

.Nav__Item a,
.Nav__Item p,
.Nav__Item li {
  cursor: pointer;
  letter-spacing: -0.025rem;
}

.Nav__Item {
  padding-left: 1.5rem;
}

.Nav__Item ul {
  list-style-type: none;
}

.Nav__List {
  display: flex;
  flex-direction: row;
}

.Nav__Item__Btn > p,
.Nav__Item > a {
  transition: 0.3s ease;
}

.Nav__Item__Btn > p {
  padding-bottom: 0.5rem;
}

.Nav__Item__Btn > p:hover,
.Nav__Item > a:hover {
  color: #4b4b4b;
}

.Footer__Links__Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Footer__Link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer__Link:hover > svg {
  fill: #489eb8;
}

.Footer__Link__SVG {
  margin: 0;
  width: 22px;
  height: 22px;
  transition: 0.3s ease;
  fill: #4b4b4b;
}

.Footer__Link:not(first-child) {
  margin: 0 .5rem;
}

.Footer__Subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Footer__Copyright {
  margin-top: 2rem;
  font-family: "MLight";
  font-size: 12px;
  color: #4b4b4b;
  opacity: 0.7;
  user-select: none;
}

p.disabled {
  cursor: not-allowed;
  user-select: none;
  color: rgba(75, 75, 75, 0.404);
}

p.disabled::after {
  content: "Soon";
  color: white;
  background-color: #48b877;
  border-radius: 5px;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
}

@media (max-width: 480px) {
  .Footer__Navigation {
    display: none;
  }

  .Footer__Subscription {
    border-top: 1px solid rgba(75, 75, 75, 0.5);
  }

  .Subscription {
    flex-direction: column;
  }

  .Subscription > p {
    margin: 0;
    margin-bottom: 1rem;
  }

  .Footer__Wrapper {
    margin-top: 4rem;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .Footer__Navigation {
    display: none;
  }

  .Footer__Subscription {
    border-top: 1px solid rgba(75, 75, 75, 0.5);
  }

  .Footer__Copyright {
    font-size: 1.25rem;
  }
}