.Exchanges__Wrapper {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: #4b4b4b;
  padding-top: 4rem;
}

h2 {
  font-family: "MBlack";
  margin: 1.75rem 0;
}

.Exchanges__Wrapper h2 span {
  color: rgba(30, 157, 171, 0.404);
}

.Exchanges__Channels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 2.5rem;
}

.Channel {
  display: flex;
  flex-direction: row;
}

.Channel__Image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  background-color: rgba(75, 75, 75, 0.404);
}

.Channel__Text__Wrapper {
  width: 60%;

  margin-left: 1rem;
}

.Channel__Name {
  width: 65%;
  height: 20px;
  margin-bottom: 0.75rem;
  border-radius: 5px;
}

.Channel__Description__Upper {
  height: 15px;
  margin-bottom: 0.25rem;
  border-radius: 5px;
}

.Channel__Description__Lower {
  width: 40%;
  height: 15px;
  border-radius: 5px;
}

.disabled {
  cursor: not-allowed;
  user-select: none;
  color: rgba(75, 75, 75, 0.404);
}

h2.disabled::after {
  content: "Soon";
  color: white;
  background-color: #48b877;
  border-radius: 5px;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 72%);
  }
  100% {
    background-color: hsl(0, 0%, 91%);
  }
}

@media (max-width: 1000px) {
  .Exchanges__Channels {
    grid-template-columns: 1fr;
  }
}
