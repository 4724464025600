.NotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  user-select: none;

  color: #4b4b4b;
}

.NotFound__Title {
  font-family: "MBlack";
  font-size: 35px;
  text-align: center;
}

.NotFound__Title__Span {
  color: #1e9eab;
  font-size: 96px;
}

@media (max-width: 1000px) {
  .NotFound__Title {
      font-size: 30px;
  }

  .NotFound__Title__Span {
    font-size: 81px;
  }
}
