.KeyComponents {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: #4b4b4b;
}

h2 {
  font-family: "MBlack";
  margin: 1.75rem 0;
}

.KeyComponents__Information::selection,
.KeyComponents__Component__Information::selection {
  color: #489eb8;
}

.KeyComponents__Information {
  width: 85%;
  font-family: "MLight";
}

.KeyComponents__Wrapper {
  width: 500px;
  padding-top: 4rem;
  padding-right: 2rem;
  padding-bottom: 0;
}

.KeyComponents__Wrapper__Components {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.KeyComponents__Component {
  margin-top: 3rem;
  width: 375px;
}

.KeyComponents__Logotype__Image {
  width: 48px;
  height: 48px;
  fill: #1e9eab;
  margin: 0;
  transition: 0.3s ease;
}

.KeyComponents__Component__Information {
  color: #4b4b4b;
  transition: 0.3s ease;
}

.KeyComponents__Component__Logotype {
  transition: 0.3s ease;
}

.KeyComponents__Component:hover {
  .KeyComponents__Component__Logotype {
    color: #4b4b4b;
  }
  .KeyComponents__Logotype__Image {
    fill: #4b4b4b;
  }
  .KeyComponents__Component__Information {
    color: #4b4b4b;
  }
}

.KeyComponents__Component:nth-child(2n) {
  margin-left: 2rem;
}

.KeyComponents__Component__Logotype > p {
  font-family: "MBold";
  margin: 1rem 0;
}

.disabled, .disabled svg, .disabled>p {
  cursor: not-allowed;
  user-select: none;
  fill: rgba(75, 75, 75, 0.404);
  color: rgba(75, 75, 75, 0.404);
}

h2.disabled::after, .disabled div p::after {
  content: "Soon";
  color: white;
  background-color: #48b877;
  border-radius: 5px;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
}

h2.disabled span {
  color: rgba(30, 157, 171, 0.404);
}

@media (max-width: 480px) {
  .KeyComponents__Component__Information {
    font-size: 14px;
  }

  .KeyComponents__Information {
    width: 100%;
  }

  .KeyComponents__Wrapper {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 0;
  }

  .KeyComponents__Wrapper__Components {
    grid-template-columns: 1fr;
  }

  .KeyComponents__Component:nth-child(2n) {
    margin-left: 0rem;
  }

  .KeyComponents__Component {
    width: 100%;
  }

  .KeyComponents {
    margin-bottom: 2rem;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .KeyComponents__Information {
    font-size: 1.5rem;
  }

  .KeyComponents__Logotype__Image {
    width: 64px;
    height: 64px;
  }

  .KeyComponents__Component__Logotype > p {
    font-size: 1.5rem;
  }

  .KeyComponents__Component__Information {
    font-size: 1.25rem;
  }
}