.Greeting {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  color: #4b4b4b;
}

h1.Greeting__Title {
  font-family: "MBlack";
  font-size: 2.25rem;
  overflow: hidden;
  border-right: 0.15em solid #1e9eab;
  white-space: nowrap;
  animation: typing 1.5s steps(45, end), blink-caret 1s step-end infinite;
}

h1.Greeting__Title span {
  display: inline-block;
  color: #1e9eab;
}

.Greeting__Information::selection {
  color: #489eb8;
}

.Greeting__Information {
  width: 85%;
  font-size: 1.125rem;
  padding-bottom: 1.6rem;
  font-family: "MLight";
}

@keyframes typing {
  from {
    width: 1%;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #1e9eab;
  }
}

.Greeting__Wrapper {
  width: 500px;
  height: 500px;
  padding-top: 4rem;
  padding-right: 2rem;
  padding-bottom: 0;
}

.Greeting__Image {
  width: 500px;
  object-fit: cover;
  box-sizing: border-box;
  padding-right: 3.4rem;
  padding-top: 4rem;
  border-radius: 50%;
  pointer-events: none;
}

.Greeting__Wrapper__Btn {
  display: flex;
  flex-direction: row;
}

/* Mobile Devices */

@media (max-width: 480px) {
  .Greeting__Image {
    display: none;
  }

  .Greeting__Wrapper {
    width: 83vw;
    height: auto;
    padding-top: 3rem;
    padding-right: 0rem;
  }

  h1.Greeting__Title {
    display: none;
  }

  .Greeting__Information {
    width: 100%;
    font-size: 1.125rem;
    padding-bottom: 1rem;
    font-family: "MLight";
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .Greeting__Image {
    display: none;
  }

  .Greeting__Wrapper {
    width: 83vw;
    height: auto;
    padding-top: 3rem;
    padding-right: 0rem;
  }

  h1.Greeting__Title {
    width: 18.5ch;
    font-size: 3rem;
  }

  .Greeting__Information {
    width: 100%;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    font-family: "MLight";
  }

  .Greeting__Wrapper__Btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}