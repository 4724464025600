.UseCases {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: #4b4b4b;
  padding-top: 4rem;
}

.UseCases__Information {
  width: 50%;
  padding-bottom: 1.6rem;
  font-family: "MRegular";
}

.UseCases__List__Item::marker {
  color: #1e9eab;
  font-size: 1.2rem;
}

.UseCases__List {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
}

.UseCases__List__Item {
  width: 400px;
  padding-bottom: 1.6rem;
  font-family: "MRegular";
}

.UseCases__List__Item__Paragraph {
    margin-left: 2rem;
}

.UseCases__List__Item::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background-image: url("../../img/diamond.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}

@media (max-width: 1000px) {
  .UseCases__Information {
    width: 100%;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .UseCases__Information {
    font-size: 1.5rem;
  }

  .UseCases__List {
    font-size: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .UseCases__List__Item::before {
    margin-top: 0.75rem;
    height: 30px;
    width: 30px;
  }
}
