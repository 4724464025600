.Coin {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  color: #4b4b4b;
}

.Coin__Wrapper > h1 {
  font-family: "MBlack";
  font-size: 2.25rem;
}

h1 span {
  display: inline-block;
  color: #1e9eab;
}

.Coin__Information::selection {
  color: #489eb8;
}

.Coin__Information {
  width: 85%;
  font-size: 1.125rem;
  padding-bottom: 1.6rem;
  font-family: "MRegular";
}

.Coin__Slogan {
  width: 85%;
  font-size: 1.125rem;
  padding-bottom: 1.6rem;
  font-family: 'MMedium'
}

.Coin__Wrapper {
  width: 500px;
  padding-top: 4rem;
  padding-right: 2rem;
  padding-bottom: 0;
}

.Coin__Image {
  width: 500px;
  object-fit: cover;
  box-sizing: border-box;
  padding-left: 3.4rem;
  padding-top: 4rem;
  pointer-events: none;
  background-image: url("../../img/coin_greeting_back.svg");
  background-repeat: no-repeat;
  background-position-y: 37px;
  
}

.Coin__Wrapper__Btn {
  display: flex;
  flex-direction: row;
}

/* Mobile Devices */

@media (max-width: 1000px) {
  .Coin {
    flex-direction: column;
  }

  .Coin__Image {
    width: 83vw;
    padding-left: 0;
    background-size: cover 50%;
    background-position-y: 60px;
  }

  .Coin__Wrapper {
    width: 83vw;
    height: auto;
    padding-top: 3rem;
    padding-right: 0rem;
  }

  h1.Coin__Title {
    width: 18.5ch;
    font-size: 1.58rem;
  }

  .Coin__Information {
    width: 100%;
    font-size: 14px;
    padding-bottom: 1rem;
    font-family: "MRegular";
  }

  .Coin__Slogan {
    font-size: 14px;
  }
}

/* Tablet */

@media (max-width: 999px) and (min-width: 481px) {
  .Coin__Information, .Coin__Slogan {
    font-size: 1.5rem;
  }
}
