* {
  box-sizing: border-box;
}

@font-face {
  font-family: MRegular;
  src: url(fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: MBlack;
  src: url(fonts/Montserrat-Black.ttf);
}

@font-face {
  font-family: MBold;
  src: url(fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: MMedium;
  src: url(fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: MLight;
  src: url(fonts/Montserrat-Light.ttf);
}

::-webkit-scrollbar {
  width: 0;
}

::selection {
  background-color: transparent;
  color: #f7bb73;
}

a,
p,
li,
ul,
span,
select {
  padding: 0;
  margin: 0;
  text-decoration: none;
}

a {
  color: #1e9eab;
}

body {
  margin: 0;
  font-family: MRegular, MBold, MBlack, MMedium, MLight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
